import { useOktaAuth } from "@okta/okta-react";
import { useLayoutEffect, useState } from "react";
import { POST } from "../../Services/io";
import { API_PATHS } from "../Utilities/constants";

const { USERS_PROFILE } = API_PATHS;

export const useDpopUser = () => {
  // State Variables
  const [, setHasDpopUser] = useState(false);

  // Custom Hooks
  const { authState } = useOktaAuth();

  const accessToken = authState?.accessToken?.accessToken;
  const claims = authState?.idToken?.claims;
  const dpopUser = authState?.dpopUser;

  // ------------------ IO -------------------
  const fetchDpopUser = async (oktaUserId, token) => {
    const response = await POST(
      USERS_PROFILE,
      {
        token,
        apiKey: true,
        "Dpop-User-Id": oktaUserId,
      },
      {}
    );
    // Setting user data with exception handling
    authState.dpopUser = response?.data || {};
    setHasDpopUser(true);
  };

  // Hooks
  useLayoutEffect(() => {
    // Fetch user profile once post authentication
    if (accessToken && claims && !dpopUser) {
      setHasDpopUser(false);
      fetchDpopUser(claims.sub, accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, claims, dpopUser]);

  return authState;
};
