import React from "react";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "../../Common/Hooks/useAuthContext";

/*
----------------------------- Notes -------------------------------------
1. Landing page component updated for fetching logged in user details as single source of truth.
2. Once signed in user name is displayed with welcome note.
*/

const Landing = () => {
  // Custom hooks
  const { dpopUser } = useAuthContext();

  // Translation hook
  const [t] = useTranslation();

  return (
    <div className="container">
      <div className="my-5 text-center">
        <h3>
          {t("Landing.welcomeBack")}, {dpopUser?.firstName}
        </h3>
      </div>
    </div>
  );
};

export default Landing;
