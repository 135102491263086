import Modal from "bootstrap/js/dist/modal";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { subscribe, unsubscribe } from "../../Services/pubSub";

const LogoutRedirectModal = () => {
  const modalRef = useRef();
  const navigate = useNavigate();

  const [userDeleted, setUserDeleted] = useState();
  const [modal, setModal] = useState();

  // Translation hook
  const [t] = useTranslation();

  // Hooks
  useEffect(() => {
    if (!modal) {
      setModal(new Modal(modalRef.current));
    }
  }, [modal]);

  useEffect(() => {
    if (modal) {
      modal.show();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDeleted]);

  useEffect(() => {
    subscribe("userDeleted", handleError);

    // Clean up subscription on component unmount
    return () => {
      unsubscribe("userDeleted", handleError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleError = (message) => {
    setUserDeleted(message);
  };

  const navigateToLogout = () => {
    navigate("/logout");
    modal.hide();
  };

  return (
    <div
      ref={modalRef}
      className="modal fade"
      tabIndex="-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ height: "auto" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("logoutModal.userDeleted")}</h5>
          </div>
          <div className="modal-body mb-3 text-center">
            {t("logoutModal.userHasBeenDeleted")}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={navigateToLogout}
            >
              {t("logoutModal.returnToSignIn")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutRedirectModal;
