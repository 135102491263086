import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LanguageIcon } from "../../Assets/Images/language.svg";
import { FEATURES } from "../../Auth/AuthCheck";
import { API_PATHS } from "../Utilities/constants";

import {
  findAuthorizedFeature,
  isCapabilityAllowed,
} from "../Utilities/helperFunctions";

import logo from "../../Assets/Images/logo-digital-pathology-im.svg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { useDpopUser } from "../Hooks/useDpopUser";
import IdleTimerAlert from "../Modals/IdleTimerAlert";
import "./NavMenu.css";

// Feature constants
const { ORDERS, SCANS, USERS } = FEATURES;
const { ORDERS_SEARCH, ACCESSION_SCAN } = API_PATHS;

const locales = {
  en: { title: "English" },
  fr: { title: "French" },
};

const NavMenu = () => {
  // State Variables
  const [ordersFeature, setOrdersFeature] = useState();
  const [scansFeature, setScansFeature] = useState();
  const [usersFeature, setUsersFeature] = useState();
  const [showModal, setShowModal] = useState(false);
  const [language, setLanguage] = useState("");

  // Custom Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const authState = useDpopUser();

  const { access, firstName, lastName } = authState?.dpopUser || {};

  const loggingOut = () => {
    navigate("/logout");
  };

  // Translation hook
  const { t, i18n } = useTranslation();

  const activeNavItem = ({ isActive }) =>
    `nav-link ${isActive ? "active nav-link-padding" : "nav-link-padding"}`;

  const changeLanguage = (locale) => {
    setLanguage(locales[locale].title);
    i18n.changeLanguage(locale);
    localStorage.setItem("user-preferred-language", locale);
  };


  // Hooks
  useLayoutEffect(() => {
    const userLanguage = localStorage.getItem("user-preferred-language");
    userLanguage ? changeLanguage(userLanguage) : changeLanguage("en");

    if (Array.isArray(access)) {
      setOrdersFeature(findAuthorizedFeature(access, ORDERS));
      setScansFeature(findAuthorizedFeature(access, SCANS));
      setUsersFeature(findAuthorizedFeature(access, USERS));
    }
  }, [access]);

  return (
    <header>
      <IdleTimerAlert showModal={showModal} setShowModal={setShowModal} />
      <nav className="navbar fixed-top navbar-expand-sm box-shadow-border bg-white py-0">
        <div className="container py-0 navbar-min-height">
          <Link className="navbar-brand py-0" to="/">
            <img className="logo" src={logo} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {location.pathname !== "/login" && authState?.isAuthenticated && (
            <div
              className="collapse navbar-collapse justify-content-between ms-sm-5"
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav">
                {isCapabilityAllowed(
                  { api: ORDERS_SEARCH, accessLevel: "POST" },
                  ordersFeature?.capabilities
                ) && (
                  <li className="nav-item text-sm-center">
                    <NavLink className={activeNavItem} to="/orders">
                      {t("navigation.orders")}
                    </NavLink>
                  </li>
                )}
                {isCapabilityAllowed(
                  { api: ACCESSION_SCAN, accessLevel: "POST" },
                  scansFeature?.capabilities
                ) && (
                  <li className="nav-item text-sm-center">
                    <NavLink className={activeNavItem} to="/scans">
                      {t("navigation.scans")}
                    </NavLink>
                  </li>
                )}
                {isCapabilityAllowed(
                  { api: ORDERS_SEARCH, accessLevel: "POST" },
                  ordersFeature?.capabilities
                ) && (
                  <li className="nav-item text-sm-center">
                    <NavLink className={activeNavItem} to="/reconciliation">
                      {t("navigation.skpReconciliation")}
                    </NavLink>
                  </li>
                )}
                {isCapabilityAllowed(
                  { api: API_PATHS.USERS, accessLevel: "GET" },
                  usersFeature?.capabilities
                ) && (
                  <li className="nav-item text-sm-center">
                    <NavLink className={activeNavItem} to="/users">
                      {t("navigation.users")}
                    </NavLink>
                  </li>
                )}
              </ul>
              <div className="d-flex align-items-center navbar-nav">
                <div className="btn-group d-flex align-items-center ms-5 me-2">
                  <LanguageIcon />
                  <div className="px-2 py-1">{language}</div>
                  <button
                    type="button"
                    className="btn dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    {Object.keys(locales).map((locale, index) => (
                      <li key={index} className="dropdown-item">
                        <button
                          className="btn"
                          onClick={() => changeLanguage(locale)}
                        >
                          {locales[locale].title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <b className="ms-5 me-2 text-queen-blue">
                  {firstName && `${firstName || ""} ${lastName || ""}`}
                </b>
                <button
                  type="button"
                  className="btn logout-button"
                  onClick={loggingOut}
                >
                  {t("logoutModal.logout")}
                </button>
              </div>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default NavMenu;
