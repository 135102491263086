import Cookies from "universal-cookie";
import { ERROR_CODES } from "../Common/Utilities/constants";
import { publish } from "./pubSub";

const handle401 = () => {
  window.location.href = "/logout";
};

const buildHeaders = ({ token, apiKey, ...additionalHeaders }) => {
  const cookies = new Cookies(null, { path: "/" });
  let sessionToken = cookies.get("Session_token");

  let headers = {
    "Content-Type": "application/json",
    "app-type": "internal",
    credentials: "include",
    Authorization: `Bearer ${token}`,
  };

  if (additionalHeaders) {
    headers = { ...headers, ...additionalHeaders };
  }
  if (sessionToken) {
    headers.session_token = sessionToken;
  }
  return headers;
};

const fetchData = async (url, options) => {
  try {
    const response = await fetch(`/api/dpodws/v1/${url}`, options);

    // Unauthorized response should only be returned due to expired Okta token and results in a log out
    if (response.status === 401) {
      handle401();
      // Empty body success response should be returned by deletes
    } else if (response.status === 204) {
      return response;
    } else if (!response.ok) {
      const errorData = await response.json();
      throw errorData?.ErrorList[0];
    }

    return await response.json();
  } catch (exception) {
    if (exception?.ErrorCode === ERROR_CODES?.USER_DELETED) {
      publish("userDeleted", exception.ErrorMessage);
    } else if (exception?.ErrorMessage) {
      publish("apiError", exception.ErrorMessage);
    }
    console.log("Failed to fetch", exception);
    return {};
  }
};

export const GET = async (url, headers) => {
  const options = {
    method: "GET",
    headers: buildHeaders(headers),
  };

  return await fetchData(url, options);
};

export const POST = async (url, headers, body) => {
  const options = {
    method: "POST",
    headers: buildHeaders(headers),
    body: JSON.stringify(body),
  };

  return await fetchData(url, options);
};

export const PUT = async (url, headers, body) => {
  const options = {
    method: "PUT",
    headers: buildHeaders(headers),
    body: JSON.stringify(body),
  };

  return await fetchData(url, options);
};

export const DELETE = async (url, headers) => {
  const options = {
    method: "DELETE",
    headers: buildHeaders(headers),
  };

  return await fetchData(url, options);
};

export const PATCH = async (url, headers, body) => {
  const options = {
    method: "PATCH",
    headers: buildHeaders(headers),
  };
  if (body) {
    options.body = JSON.stringify(body);
  }

  return await fetchData(url, options);
};

export const MOCK_FETCH = ({ fail }) => {
  return new Promise((resolve, reject) => {
    if (fail) {
      setTimeout(() => reject({ failed: true }), Math.random() * 10000);
    }
    setTimeout(() => resolve({ success: true }), Math.random() * 10000);
  });
};
