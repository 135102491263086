/* 
Empty check for null, undefined, object, array, string, number and date
*/

export function isEmpty(val) {
  if (val === null || val === undefined || typeof val === "number") {
    return !!!val;
  }
  if (val instanceof Date && !isNaN(val)) {
    return !!!val;
  }
  if (Array.isArray(val)) {
    return !!!val.length;
  }
  if (typeof val === "object") {
    return Object.keys(val).length === 0;
  }
  if (typeof val === "string") {
    return !val.trim();
  }
  throw new Error("unknown type");
}

/* DateFunctions */
const dayConversion = 60 * 60 * 24 * 1000;

const formatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };

export function todayDate() {
  return new Date(new Date().getTime()).toLocaleDateString(
    "en-US",
    formatOptions
  );
}

export function nextDayDate(dateStr) {
  const nextDate = new Date(dateStr);
  nextDate.setDate(nextDate.getDate() + 1);
  return nextDate.toLocaleDateString("en-US", formatOptions);
}

export function yesterdayDate() {
  return new Date(new Date().getTime() - dayConversion).toLocaleDateString(
    "en-US",
    formatOptions
  );
}

export function oneWeekAgoDate() {
  return new Date(new Date().getTime() - dayConversion * 7).toLocaleDateString(
    "en-US",
    formatOptions
  );
}

export function twoWeeksAgoDate() {
  return new Date(new Date().getTime() - dayConversion * 14).toLocaleDateString(
    "en-US",
    formatOptions
  );
}

// Provides locale date 1 year in future in yyyy-mm-dd format
export function oneYearFromNowLocaleDate() {
  return new Date(
    new Date().getTime() + dayConversion * 365
  ).toLocaleDateString("en-CA");
}

// Provides local-to-utc date-string with timestamp
export function localToUtcDateWithTimeStamp(dateStr, timestamp) {
  if (isEmpty(dateStr) || isEmpty(timestamp)) {
    return null;
  }
  return new Date(`${dateStr} ${timestamp}`).toISOString();
}

// Provides utc-to-local date-string in mm-dd-yyyy format
export function utcToLocalmmddyyyyString(date) {
  if (isEmpty(date)) {
    return null;
  }
  const [month, day, year] = new Date(date)
    .toLocaleDateString("en-US", formatOptions)
    .split("/");
  return `${month}-${day}-${year}`;
}

// Provides date-string in yyyy-mm-dd to mm/dd/yyyy format
export function yyyymmddTommddyyyyString(date) {
  if (isEmpty(date)) {
    return "";
  } else if (date.includes("-")) {
    const [year, month, day] = date.split("-");
    return `${month}/${day}/${year}`;
  } else {
    return date;
  }
}

// Provides date-string in mm/dd/yyyy to yyyy-mm-dd format
export function mmddyyyyToyyyymmddString(date) {
  if (isEmpty(date)) {
    return "";
  } else if (date.includes("/")) {
    const [month, day, year] = date.split("/");
    return `${year}-${month}-${day}`;
  } else {
    return date;
  }
}

// Returns local date with time
export function toLocaleDateStringWithTime(date) {
  const options = { hour: "numeric", hour12: "true", minute: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

export function concatText(inputString, limit) {
  if (inputString.length <= limit) {
    return inputString;
  } else {
    return inputString.substring(0, limit - 3) + "...";
  }
}

/* Capabilities */
export const findAuthorizedFeature = (featuresList = [], featureName) => {
  const authorizedFeature = featuresList.find(
    ({ feature }) => feature === featureName
  );
  return authorizedFeature;
};

export const isCapabilityAllowed = (capabilityObj = {}, capabilities = []) => {
  const capabilityFound = capabilities.find(
    (capability) =>
      capability?.api === capabilityObj?.api &&
      capability?.accessLevel === capabilityObj?.accessLevel
  );
  return capabilityFound !== undefined;
};

export const checkAdmin = (roles) => {
  if (!roles) return;
  return roles.find((role) => role.code === "USER_ADMIN");
};

/* Miscellaneous */
export const reloadPage = () => window.location.reload(false);

export const isValidEmail = (email, isSearch) => {
  const emailRegex = isSearch ?
    /^[a-zA-Z][a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]*(@[a-zA-Z0-9.-]*)?(\.[a-zA-Z]*)?$/ :
    /^[a-zA-Z][a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return email === "" || emailRegex.test(email);
};
