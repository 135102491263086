/*
----------------------------- Notes -------------------------------------
1. A singleton class to act as sharing data-store 
*/

class DataStore {
  constructor() {
    if (!DataStore.instance) {
      this.data = {};
      DataStore.instance = this;
    }
    return DataStore.instance;
  }

  setData(key, value) {
    this.data[key] = value;
  }

  getData(key) {
    return this.data[key];
  }
}

const dataStore = new DataStore();
// To prevent any modification of the instance
Object.freeze(dataStore);

export default dataStore;
