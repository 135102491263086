import oktaLogo from "./Assets/Images/okta-ar21.svg";
import { isEmpty } from "./Common/Utilities/helperFunctions";

/* 
In Okta Sign-In Widget version 7+, Okta Identity Engine is enabled by default. 
If you are using version 7+ and want to use Okta Classic Engine rather than Identity Engine, 
you need to specify useClassicEngine: true in the configuration options
*/

const getOktaConfig = (envConfig) => {
  const oktaDomain = envConfig?.OKTA_DOMAIN;
  const oktaAuthServer = envConfig?.OKTA_AUTH_SERVER;

  const oktaAuthConfig = {
    issuer: `https://${oktaDomain}/oauth2/${oktaAuthServer}`,
    clientId: envConfig?.OKTA_CLIENT_ID,
    scopes: envConfig?.OKTA_API_SCOPES || [
      "CIAM_TENANT",
      "openid",
      "profile",
      "email",
    ],
    redirectUri: `${window.location.origin}/login/callback`,
    logo: oktaLogo,
    useClassicEngine: true,
    transformAuthState: async (oktaAuth, authState) => {
      if (!authState.isAuthenticated) {
        return authState;
      }
      try {
        // User must have valid Okta SSO session
        if (isEmpty(authState.oktaUser)) {
          const oktaUser = await oktaAuth.token.getUserInfo();
          authState.isAuthenticated = !!oktaUser;
          authState.oktaUser = oktaUser;
        }
      } catch (error) {
        console.log(error);
      }
      return authState;
    },
  };

  const oktaSignInConfig = {
    ...oktaAuthConfig,
  };

  return { oktaAuthConfig, oktaSignInConfig };
};

export default getOktaConfig;
