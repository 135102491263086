import { OktaAuth } from "@okta/okta-auth-js";
import React, { useEffect, useState } from "react";
import App from "./App";
import { ENV_CONFIG } from "./Common/Utilities/constants";
import dataStore from "./DataStore";
import fetchEnvConfig from "./Services/env";
import getOktaConfig from "./oktaConfig";

const Root = () => {
  const [oktaAuth, setOktaAuth] = useState(null);
  const [oktaSignInConfig, setOktaSignInConfig] = useState(null);
  const [envConfig, setEnvConfig] = useState(dataStore.getData(ENV_CONFIG));

  useEffect(() => {
    if (!envConfig) {
      const getEnvConfig = async () => {
        let fetchedConfig = null;
        const response = await fetchEnvConfig();
        if (response) {
          fetchedConfig = response;
        } else if (process.env?.REACT_APP_OKTA_DOMAIN) {
          // Fallback to build time environment configuration
          fetchedConfig = {
            OKTA_DOMAIN: process.env?.REACT_APP_OKTA_DOMAIN,
            OKTA_AUTH_SERVER: process.env?.REACT_APP_OKTA_AUTH_SERVER,
            OKTA_CLIENT_ID: process.env?.REACT_APP_OKTA_CLIENT_ID,
            OKTA_API_SCOPES: process.env?.REACT_APP_OKTA_API_SCOPES?.split(","),
          };
        }
        // Save the envConfig in singleton data store of later use
        dataStore.setData(ENV_CONFIG, fetchedConfig);
        setEnvConfig(fetchedConfig);
      };
      getEnvConfig();
    }
  }, [envConfig]);

  // Create oktaConfig from envConfig
  useEffect(() => {
    if (envConfig && (!oktaAuth || !oktaSignInConfig)) {
      const { oktaAuthConfig, oktaSignInConfig } = getOktaConfig(envConfig);
      setOktaSignInConfig(oktaSignInConfig);
      setOktaAuth(new OktaAuth(oktaAuthConfig));
    }
  }, [envConfig, oktaAuth, oktaSignInConfig]);

  // Render a loading screen or fallback while fetching the configuration
  if (!envConfig || !oktaAuth || !oktaSignInConfig) {
    return (
      <div className="d-flex justify-content-center flex-nowrap">
        Loading...
      </div>
    );
  }

  // Render App component
  return <App {...{ oktaAuth, oktaSignInConfig }} />;
};

export default Root;
