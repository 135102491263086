import { useDpopUser } from "./useDpopUser";

export const useAuthContext = () => {
  // Custom Hooks
  const authState = useDpopUser();

  // Auth context variables
  const token = authState?.accessToken?.accessToken;
  const userId = authState?.idToken?.claims?.sub;
  const dpopUser = authState?.dpopUser;
  const handle = dpopUser?.handle;

  // ----------------------------- Helper Functions -----------------------------------
  const setDpopUser = (dpopUser) => {
    authState.dpopUser = dpopUser;
  };

  return {
    dpopUser,
    setDpopUser,
    authParams: {
      token,
      handle,
      "Dpop-User-Id": userId,
    },
  };
};
