import React, { Suspense, useLayoutEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useDpopUser } from "../Common/Hooks/useDpopUser";
import Spinner from "../Common/Spinner/Spinner";

import NotAuthorized from "../Auth/NotAuthorized";
import {
  findAuthorizedFeature,
  isCapabilityAllowed,
  isEmpty,
} from "../Common/Utilities/helperFunctions";

/*
----------------------------- Notes -------------------------------------
1. Protected route preventing unauthorized access to privileged sections 
*/

const ProtectedRoute = ({ routeFeature, keyCapability, children }) => {
  // State Variables
  const [, setHasDpopUser] = useState(false);

  // Custom Hooks
  const location = useLocation();
  const authState = useDpopUser();

  const dpopUser = authState?.dpopUser;

  // Hooks
  useLayoutEffect(() => {
    setHasDpopUser(dpopUser !== undefined);
  }, [dpopUser]);

  // On waiting for authState to initialize
  if (isEmpty(authState)) {
    return (
      <div className="my-5">
        <Spinner />
      </div>
    );
  }

  // On !authState?.isAuthenticated navigate to Login page
  if (!authState?.isAuthenticated) {
    return (
      <Navigate
        to="/login"
        state={{ path: location.pathname + location.search }}
        replace
      />
    );
  }

  /* 
  On waiting for the authenticated user profile, only undefined check is 
  enough for better user experience
  */
  if (dpopUser === undefined) {
    return (
      <div className="my-5">
        <Spinner />
      </div>
    );
  }

  /* 
  On path is root, rendering Outlet for better user experience
  */
  if (location.pathname === "/") {
    return <Outlet />;
  }

  // On validating the authorization of the feature
  if (Array.isArray(dpopUser?.access)) {
    // Finding the feature
    const authorizedFeature = findAuthorizedFeature(
      dpopUser?.access,
      routeFeature
    );
    // Making sure the primary / key capability is allowed
    if (
      authorizedFeature &&
      isCapabilityAllowed(keyCapability, authorizedFeature?.capabilities)
    ) {
      return (
        <Suspense fallback={<></>}>
          {children ? (
            children
          ) : (
            <Outlet
              context={{ capabilities: authorizedFeature?.capabilities || [] }}
            />
          )}
        </Suspense>
      );
    }
  }

  // On unauthorized
  return <NotAuthorized />;
};

export default ProtectedRoute;
